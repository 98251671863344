import React from 'react';
import Helmet from 'react-helmet';
import ReactPlayer from 'react-player';
import { RouteComponentProps } from '@reach/router';
import { graphql } from 'gatsby';
import withSizes from 'react-sizes';

import styled from 'styled-components';

import { breakpoints as nibBreakpoints } from '@nib-components/theme';

import Section from '@nib-components/section';
import Heading from '@nib-components/heading';
import Container from '@nib-components/container';
import Copy from '@nib-components/copy';
import colors from '@nib-components/colors';
import Link from '@nib-components/link';
import { Stack, Inline } from '@nib/layout';
import {
  TwitterSystemIcon,
  FacebookSystemIcon,
  EmailSystemIcon,
  UmbrellaGraphicIcon,
  PieChartGraphicIcon,
  PriceGraphicIcon,
} from '@nib/icons';

import Layout from '../components/Layout';
import metrics from '../metrics';
import { ContentfulBlogArticle, MarkdownHtml, UtilityLinkModel } from '../constructs/models';
import { getFormattedDateFromDateString } from '../utils/moment-utils';
import UtilityButtons from '../components/UtilityButtons';
import { urlConstants } from '../constructs/constants';
import { compose } from 'lodash/fp';
import { GatsbyImage } from 'gatsby-plugin-image';

export const articlePageQuery = graphql`
  query ($slug: String!) {
    allContentfulBlogArticle(filter: { urlSlug: { eq: $slug } }) {
      edges {
        node {
          id
          heroType
          title
          urlSlug
          startDate
          videoUrl
          metaDataPageTitle
          metaDataPageDescription {
            metaDataPageDescription
          }
          seoSchema {
            internal {
              content
            }
          }
          summary {
            summary
          }
          heroImage {
            file {
              url
            }
            gatsbyImageData(layout: FULL_WIDTH)
            title
          }
          hideQuoteWidget
          sectionOneHeading
          sectionOneContent {
            childMarkdownRemark {
              html
            }
          }
          sectionTwoHeading
          sectionTwoContent {
            childMarkdownRemark {
              html
            }
          }
          sectionThreeHeading
          sectionThreeContent {
            childMarkdownRemark {
              html
            }
          }
          sectionFourHeading
          sectionFourContent {
            childMarkdownRemark {
              html
            }
          }
          sectionFiveHeading
          sectionFiveContent {
            childMarkdownRemark {
              html
            }
          }
          importantThingsToKnow {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

interface GraphQlBlogArticle {
  allContentfulBlogArticle: AllContentfulBlogArticle;
}

interface AllContentfulBlogArticle {
  edges: ContentfulBlogArticleEdgesShape[];
}

interface ContentfulBlogArticleEdgesShape {
  node: ContentfulBlogArticle;
}

interface BlogDetailsProps extends RouteComponentProps {
  data: GraphQlBlogArticle;
  isMobile: boolean;
}

const ArticleContentfulSection = (props: {
  isMobile: boolean;
  article: Partial<ContentfulBlogArticle>;
}) => {
  const {
    articleContent,
    sectionOneHeading,
    sectionOneContent,
    sectionTwoHeading,
    sectionTwoContent,
    sectionThreeHeading,
    sectionThreeContent,
    sectionFourHeading,
    sectionFourContent,
    sectionFiveHeading,
    sectionFiveContent,
  } = props.article;

  const ArticlesContainer = styled.div`
    overflow: auto;
  `;

  return (
    <>
      <ArticlesContainer>
        <ContentSection content={articleContent} />
        <ParagraphSection heading={sectionOneHeading} content={sectionOneContent} />
        <ParagraphSection heading={sectionTwoHeading} content={sectionTwoContent} />
        <ParagraphSection heading={sectionThreeHeading} content={sectionThreeContent} />
        <ParagraphSection heading={sectionFourHeading} content={sectionFourContent} />
        <ParagraphSection heading={sectionFiveHeading} content={sectionFiveContent} />
      </ArticlesContainer>
      <WantToKnowMore />
    </>
  );
};

const Article = (props: BlogDetailsProps): JSX.Element => {
  const { data, isMobile } = props;
  const articleData = data.allContentfulBlogArticle.edges[0].node;

  const { title, metaDataPageTitle, metaDataPageDescription, importantThingsToKnow, seoSchema } =
    articleData;

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        {metaDataPageTitle && <meta name="title" content={metaDataPageTitle} />}
        {metaDataPageDescription?.metaDataPageDescription && (
          <meta name="description" content={metaDataPageDescription?.metaDataPageDescription} />
        )}
        {seoSchema?.internal?.content && (
          <script type="application/ld+json">{seoSchema?.internal?.content}</script>
        )}
      </Helmet>
      <Stack space={4}>
        <HeaderSection title={title} />
        <HeroSection article={articleData} />
        <ArticleContentfulSection article={articleData} isMobile={isMobile} />
        <ParagraphSection heading="Important things to know" content={importantThingsToKnow} />
      </Stack>
    </Layout>
  );
};

const ParagraphSection = ({ heading, content }): JSX.Element => (
  <Container>
    {content && (
      <Section>
        <SectionHeading heading={heading} />
        <ContentSection content={content} />
      </Section>
    )}
  </Container>
);

const WantToKnowMore = (): JSX.Element => {
  const utilityLinks: UtilityLinkModel[] = [
    {
      title: 'Health insurance?',
      icon: UmbrellaGraphicIcon,
      url: '/health-insurance',
    },
    {
      title: 'Compare plans',
      icon: PieChartGraphicIcon,
      url: '/compare-plans',
    },
    {
      title: "What's my cost",
      icon: PriceGraphicIcon,
      url: urlConstants.getQuickQuote(),
    },
  ];
  return (
    <Section background="sneezy" padding={6}>
      <Stack space={6}>
        <SectionHeading heading={'Want to know more?'} />
        <UtilityButtons buttons={utilityLinks} flex={true} />
      </Stack>
    </Section>
  );
};

const SectionHeading = ({ heading }: { heading?: string }): JSX.Element => (
  <Heading size={{ sm: 3, lg: 2 }} component="h1">
    {heading}
  </Heading>
);

interface HeaderSectionProps {
  title: string;
}

const HeaderSection = (props: HeaderSectionProps): JSX.Element => {
  const { title } = props;

  return (
    <Section>
      <Container>
        <Heading size={{ sm: 2, lg: 1 }} component="h1">
          {title}
        </Heading>
      </Container>
    </Section>
  );
};

interface HeroSectionProps {
  article: ContentfulBlogArticle;
}

const HeroSection = (props: HeroSectionProps): JSX.Element => {
  const { article } = props;

  const { startDate, heroImage, heroType, videoUrl, urlSlug, title, summary } = article;

  return (
    <Container>
      <Stack space={1}>
        <Copy color={colors.grumpy}>{getFormattedDateFromDateString(startDate)}</Copy>
        {heroType === 'Image' ? (
          <HeroImage
            gatsbyImageData={(heroImage && heroImage.gatsbyImageData) || ''}
            alt={(heroImage && heroImage.title) || ''}
          />
        ) : (
          <HeroVideo url={videoUrl || ''} />
        )}
        <ShareButtons urlSlug={urlSlug} title={title} summary={summary.summary} />
      </Stack>
    </Container>
  );
};

interface HeroImageProps {
  alt: string;
  gatsbyImageData: any;
}
const HeroImage = (props: HeroImageProps): JSX.Element => {
  return (
    <GatsbyImage
      image={props.gatsbyImageData}
      alt={props.alt}
      style={{ width: '100%' }}
      imgStyle={{ width: '100%' }}
    />
  );
};

interface HeroVideoProps {
  url: string;
}
const HeroVideo = (props: HeroVideoProps): JSX.Element => {
  return <ReactPlayer {...props} width="100%" controls={true} />;
};

interface ShareButtonsProps {
  urlSlug: string;
  title: string;
  summary: string;
}

const ShareButtons = (props: ShareButtonsProps): JSX.Element => {
  const { urlSlug, title, summary } = props;

  const url = `https://www.nib.co.nz/free-resources/article/${urlSlug}`;
  const encodedUrl = encodeURI(url);
  const encodedTitle = encodeURI(title);
  const encodedEmailBody = encodeURI(`${summary}\n\n${url}`);

  const facebookShareHref = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const twitterShareHref = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}&via=nibNewZealand`;
  const mailShareHref = `mailto:?subject=${encodedTitle}&body=${encodedEmailBody}`;

  return (
    <Inline space={2} align="right">
      <Link href={facebookShareHref} color="dark" underline={false}>
        <FacebookSystemIcon />
      </Link>
      <Link href={twitterShareHref} color="dark" underline={false}>
        <TwitterSystemIcon />
      </Link>
      <Link href={mailShareHref} color="dark" underline={false}>
        <EmailSystemIcon />
      </Link>
    </Inline>
  );
};

const ContentSection = ({ content }: { content?: MarkdownHtml }): JSX.Element => (
  <Container>
    <Copy
      measure={false}
      component="div"
      dangerouslySetInnerHTML={{ __html: content?.childMarkdownRemark?.html }}
    />
  </Container>
);

const enhancer = compose(
  withSizes(({ width }) => ({ isMobile: width && width <= nibBreakpoints.sm })),
  metrics({ pageName: 'article' })
);

export default enhancer(Article);
